import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import loadable from '@loadable/component'
import {
  useAuth,
  getAuth,
  breakpoints,
  Anchor,
  CLOrder,
  getFullOrder,
  useResolution,
  useLocation,
  useShoppingCart,
  hexToRGBA,
} from '@ecommerce/shared'
import Layout from '../../components/Layout'
import { PgPageProps } from '../../types/PgPages'
import { Icon } from '../../components/Icon/Icon'
import { OrderLineItem, OrderLineItemSkeleton } from '../../components/OrderLineItem'
import UserMenu from '../../templates/UserMenu'
import { UserMenuMobileNavbar } from '../../components/NavBar/UserMenuMobileNavbar'

const OrderSummarySkeleton = loadable(() => import('../../components/OrderSummary/OrderSummarySkeleton'))
const OrderSummary = loadable(() => import('../../components/OrderSummary'))

const cssPrefix = `OrderDetail__`

const Wrapper = styled.div`
  .${cssPrefix} {
    &content {
      max-width: 335px;
      padding: 0;
      &-header {
        border-top: 2px solid ${(props) => hexToRGBA(props.theme.colors.black20, 0.1)};
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &content {
        max-width: initial;
        min-height: calc(100vh - 400px);
      }
      &list-container {
        overflow-y: auto;
        scrollbar-width: auto;
      }
      &back {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &-link {
          font-size: 15px;
        }
        &-icon {
          margin-bottom: -4px;
        }
        &-text {
          color: ${(props) => props.theme.colors.black};
          margin-left: 10px;
        }
      }
    }
  }
`

const MyOrders = (props: PgPageProps) => {
  const {
    toCurrency,
    state: { country },
  } = useLocation()

  const params = new URLSearchParams(props.location.search)
  const orderId = params.get('id') || ''

  const [order, setOrder] = useState<CLOrder | null>(null)
  const [isFetchingOrder, setIsFetchingOrder] = useState(true)

  const { isDesktop } = useResolution()

  const {
    state: { firstName, lastName },
  } = useAuth()
  const isAuth = getAuth()
  useEffect(() => {
    if (!orderId) navigate(`/404`)
    if (!isAuth) navigate(`/`)
  }, [])

  const {
    state: { globalQuantity },
  } = useShoppingCart()

  useEffect(() => {
    getFullOrder({ orderId, country })
      .then((fetchedOrder) => {
        setOrder(fetchedOrder)
        setIsFetchingOrder(false)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e)
        navigate('/404')
      })
  }, [])

  const getOrderLines = () => {
    return order?.relationships.line_items.objects || []
  }

  const goBackElement = (
    <div className={`${cssPrefix}back`}>
      <Anchor className={`${cssPrefix}back-link`} onClick={() => navigate(`/my-orders`)}>
        <Icon className={`${cssPrefix}back-icon`} size="20" iconId="arrow_left" />
        <span className={`${cssPrefix}back-text`}>Mis Pedidos</span>
      </Anchor>
    </div>
  )

  const orderDiscountAmount = Math.abs(
    order?.attributes.gift_card_amount_cents ?? order?.attributes.discount_amount_cents ?? 0,
  )
  const orderFormattedDiscount =
    orderDiscountAmount > 0 && (order?.attributes.metadata?.couponCode || order?.attributes.gift_card_code)
      ? toCurrency(orderDiscountAmount)
      : undefined

  return (
    <Layout
      title={`Detalle de Pedido ${order ? ` N° ${order?.attributes.number}` : ''}`}
      navbar={isDesktop ? undefined : UserMenuMobileNavbar({})}
    >
      <Wrapper>
        <UserMenu
          customerName={`${firstName} ${lastName}`}
          title="Detalle de Pedido"
          activeMenuIndex={3}
          contentClassName={`${cssPrefix}content`}
          goBackElement={goBackElement}
          cartHasProducts={globalQuantity > 0}
        >
          {isFetchingOrder ? (
            <OrderSummarySkeleton
              fieldsNumber={6}
              showControls={false}
              className={`${cssPrefix}content-header`}
              bordered={false}
            />
          ) : (
            order && (
              <OrderSummary
                order={{
                  ...order,
                  formattedAmountDiscount: orderFormattedDiscount,
                  formattedAmountCents: toCurrency(
                    order.attributes.gift_card_amount_cents
                      ? order.attributes.total_amount_with_taxes_cents
                      : order.attributes.total_amount_cents,
                  ),
                }}
                showControls={false}
                showOrderNumber
                showProductsCount
                className={`${cssPrefix}content-header`}
                bordered={false}
              />
            )
          )}
          {isFetchingOrder
            ? [...Array(5)].map((el, i) => <OrderLineItemSkeleton key={i} />)
            : getOrderLines().map((line) => <OrderLineItem key={line.id} orderLine={line} />)}
        </UserMenu>
      </Wrapper>
    </Layout>
  )
}

export default MyOrders
